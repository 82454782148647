.content-section {
  padding-bottom: $mediumPaddingY;
  padding-top: $mediumPaddingY;

  &__white-border {
    h1 {
      @include withBorderBottomCenter($borderColor : $wColor);
    }
  }

  &__inverse {
    color: $wColor;

    h1,
    h2,
    h3,
    h4 {
      color: $wColor !important;
    }

    h1 {
      &:after {
        border-bottom: 4px solid $color-4;
      }
    }
  }

  &--small-padding-bottom {
    padding-bottom: $mediumPaddingY / 2;
  }

  &--small-padding-top {
    padding-top: $mediumPaddingY / 2;
  }

  &--no-padding-bottom {
    padding-bottom: 0
  }

  &--no-padding-top {
    padding-top: 0
  }

  h1,
  h2,
  h3,
  h4 {
    color: $color-2;
  }

  h1 {
    font-size: $largeFontSize;
    margin-bottom: $defaultMarginY;
    @include md {
      font-size: $xLargeFontSize;
      margin-bottom: $defaultMarginY * 2;
    }
    @include withBorderBottomCenter();

    &.orange-border {
      @include withBorderBottomCenter($borderColor : $color-3);
    }
    &.icon-before {
      margin-top: 58px;
      @include md {
        margin-top: 74px;
      }

      &:before {
        background-repeat: no-repeat;
        background-size: 48px 48px;
        content: "";
        display: flex;
        height: 48px;
        left: 50%;
        position: absolute;
        top: -58px;
        width: 48px;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);

        @include md {
          background-size: 64px 64px;
          height: 64px;
          top: -74px;
          width: 64px;
        }
      }

      &.icon-website:before {
        background-image: url('../images/icon_website.svg');
      }

      &.icon-consulting:before {
        background-image: url('../images/icon_consulting.svg');
      }
    }
  }

  h2 {
    font-size: $mediumFontSize;
    margin-bottom: $defaultMarginY / 2;
    text-align: center;

    @include md {
      font-size: $largeFontSize;
      margin-bottom: $defaultMarginY;
    }
  }

  h3 {
    font-size: $mediumFontSize;

    @include md {
      font-size: $smallPlusFontSize;
    }

    @include lg {
      font-size: $mediumFontSize;
    }
  }

  h4 {
    font-size: $normalFontSize;

    @include md {
      font-size: $mediumFontSize;
    }
  }

  p, li {
    font-size: $normalFontSize;

    @include md {
      font-size: 1.125em; /*18px*/
    }
  }

  @include lg {
    p { font-size: 1.25em; /*20px*/ }
  }
}
